import { styled } from "../../index";
export const WhiteBoxBase = ({ defaultPadding }) => styled.div `
    padding: ${(props) => props.padding || defaultPadding || "40px 56px"};
    background-color: white;
    @media screen and (max-width: 991px) {
        padding: 25px;
    }
    @media screen and (max-width: 767px) {
        padding: 20px;
    }
`;
