import * as SelectPrimitive from "@radix-ui/react-select";
import * as React from "react";
import { ChevronDown } from "../components";
import { cn } from "./util";
const Select = SelectPrimitive.Root;
const SelectIcon = SelectPrimitive.Icon;
const SelectGroup = SelectPrimitive.Group;
const SelectValue = SelectPrimitive.Value;
const SelectTrigger = React.forwardRef(({ children, ...props }, ref) => (<SelectPrimitive.Trigger ref={ref} {...props}>
        {children}
    </SelectPrimitive.Trigger>));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
const SelectContent = React.forwardRef(({ children, position = "popper", ...props }, ref) => (<SelectPrimitive.Portal>
        <SelectPrimitive.Content ref={ref} position={position} {...props}>
            <SelectPrimitive.ScrollUpButton className={cn("flex cursor-default items-center justify-center py-1")}>
                <ChevronDown height={20} width={20} aria-hidden="true" strokeWidth={2}/>
            </SelectPrimitive.ScrollUpButton>
            <SelectPrimitive.Viewport className={cn("p-1", position === "popper" &&
        "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]")}>
                {children}
            </SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className={cn("flex cursor-default items-center justify-center py-1")}>
                <ChevronDown className="rotate-180" height={20} width={20} aria-hidden="true" strokeWidth={2}/>
            </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>));
SelectContent.displayName = SelectPrimitive.Content.displayName;
const SelectItem = React.forwardRef(({ children, ...props }, ref) => (<SelectPrimitive.Item ref={ref} {...props}>
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>));
SelectItem.displayName = SelectPrimitive.Item.displayName;
export { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectItem, SelectIcon };
