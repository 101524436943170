import React, { createContext, useCallback, useContext } from "react";
export const I18nCtx = createContext({
    translations: {},
});
export const useI18nCtx = () => useContext(I18nCtx);
export const useI18n = () => {
    const { translations } = useI18nCtx();
    const $t = useCallback((key, replace = {}) => {
        var _a;
        if (!(translations === null || translations === void 0 ? void 0 : translations[key]))
            return `${key === null || key === void 0 ? void 0 : key.replace(/{([^}]+)}/g, (ori, k) => replace[k] || ori)}`;
        return ((_a = translations === null || translations === void 0 ? void 0 : translations[key]) === null || _a === void 0 ? void 0 : _a.replace(/{([^}]+)}/g, (ori, k) => replace[k] || ori)) || `${key}`;
    }, [translations]);
    return { $t };
};
export const I18n = ({ t, as }) => {
    const { $t } = useI18n();
    if (!as)
        return <>{$t(t)}</>;
    return React.createElement(as, {}, <>{$t(t)}</>);
};
