import classNames from "classnames";
export const AlertBoxBase = ({ text, type, className, }) => {
    const variants = {
        normal: "text-gray-alertBox bg-gray-bg",
        warning: "text-yellow-mcKenzie bg-yellow-light",
        error: "text-red-error bg-pink-salmon",
        default: "text-try-dracula bg-white",
    };
    return (<div className={classNames("my-5 w-full p-3.75 uppercase", variants[type] || variants.default, className)}>
            {text}
        </div>);
};
