import { AWSClient } from "./index";
const STORE_ID = process.env.NEXT_PUBLIC_STORE_ID;
export const orderClient = () => AWSClient(process.env.NEXT_PUBLIC_AWS_ORDERS_ENDPOINT || "");
export const returnOrders = (page) => orderClient()({
    method: "GET",
    uri: `/return/orders?storeId=${STORE_ID}` + (page ? `&limit=${page === null || page === void 0 ? void 0 : page.limit}&offset=${page === null || page === void 0 ? void 0 : page.offset}` : ""),
}).then((res) => res.body);
export const returnOrderDetail = async (orderNumber, email) => orderClient()({
    method: "POST",
    uri: `/return/search`,
    body: { email, storeId: STORE_ID, orderNumber },
});
export const returnCreate = async (orderNumber, details) => orderClient()({
    method: "POST",
    uri: `/return/${orderNumber}`,
    body: { details },
});
//MOVE TO AWS
export const getOrdersDetail = async (orderNumbers) => {
    const response = await fetch("/api/return/orders-detail", {
        method: "POST",
        body: JSON.stringify({ orderNumbers: orderNumbers }),
    });
    return await response.json();
};
