import { JC_FRIEND_SKU, addUserAddress, assignCart, checkLength, getCart, localStorage, nameValidation, onlyDigitsMaxLength, postCodeValidation, typedBoolean, updateUserAddress, } from "@jog/shared";
import { autorun, makeAutoObservable } from "mobx";
const validateUserAddress = (userAddress) => {
    // if (!userAddress.gender) return false
    if (!userAddress.country)
        return false;
    if (!nameValidation(userAddress.name))
        return false;
    if (!nameValidation(userAddress.familyName))
        return false;
    if (!postCodeValidation(userAddress.postalCode, userAddress.country, true))
        return false;
    if (!onlyDigitsMaxLength(userAddress.houseNumber))
        return false;
    if (!checkLength(userAddress.streetName))
        return false;
    if (!checkLength(userAddress.city))
        return false;
    // if (!phoneValidation(userAddress.phoneNumber, false, [userAddress.country].filter(typedBoolean))) return false
    return true;
};
const completeUserAddress = (cartAddress) => {
    return {
        country: cartAddress.country,
        name: cartAddress.firstName,
        familyName: cartAddress.lastName,
        streetName: cartAddress.streetName,
        houseNumber: cartAddress.streetNumber,
        addition: cartAddress.additionalStreetInfo,
        postalCode: cartAddress.postalCode,
        city: cartAddress.city,
        phoneNumber: cartAddress.phone,
    };
};
export class CartStore {
    initCart() {
        const _cartView = localStorage.getItem("createdCart");
        if (_cartView) {
            this.cartView = _cartView;
            this.isCartViewLoaded = true;
        }
    }
    constructor(options) {
        this.options = options;
        this.cartView = null;
        this.isCartViewLoaded = false;
        this.isCtaDisabled = false;
        this.disableCheckoutButton = false;
        this.addProductFetching = false;
        this.billingAddress = null;
        this.shippingAddress = null;
        this.isCheckoutBillingFormError = false;
        this.isDeliverAddressError = false;
        this.isAddedProductModalShown = false;
        this.shippingMethods = [];
        this.isGiftcardError = false;
        this.lastAddedSku = "";
        this.deleteProductLoading = false;
        this.userEmail = "";
        this.actionList = [];
        makeAutoObservable(this, {}, { autoBind: true });
        autorun(async (reaction) => {
            this.initCart();
            reaction.dispose();
        });
    }
    setCart(cart) {
        localStorage.setItem("createdCart", cart);
        this.cartView = cart;
    }
    get cartLineItems() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems) === null || _c === void 0 ? void 0 : _c.filter((lineItem) => lineItem.sku !== JC_FRIEND_SKU);
    }
    get isJcFriendInCart() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems) === null || _c === void 0 ? void 0 : _c.find((lineItem) => lineItem.sku === JC_FRIEND_SKU);
    }
    get isOnlyJcFriendInCart() {
        var _a, _b, _c;
        return ((_c = (_b = (_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems) === null || _c === void 0 ? void 0 : _c.length) === 1 && this.isJcFriendInCart;
    }
    get cartTotalItems() {
        var _a, _b;
        const totalQuantityOfItems = ((_b = (_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.totalQuantityOfItems) || 0;
        return this.isJcFriendInCart ? totalQuantityOfItems - 1 : totalQuantityOfItems;
    }
    get cartLineItemsCount() {
        var _a, _b;
        const lineItems = (_b = (_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems;
        if (!lineItems)
            return 0;
        return this.isJcFriendInCart ? Number(lineItems.length) - 1 : lineItems.length;
    }
    get getCartPrices() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const cartViewBody = (_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body;
        const originSubTotal = ((_d = (_c = (_b = cartViewBody === null || cartViewBody === void 0 ? void 0 : cartViewBody.lineItems) === null || _b === void 0 ? void 0 : _b.filter((item) => { var _a, _b; return typedBoolean((_b = (_a = item.prices) === null || _a === void 0 ? void 0 : _a.totalPrice) === null || _b === void 0 ? void 0 : _b.centAmount); })) === null || _c === void 0 ? void 0 : _c.map((item) => { var _a, _b; return ((_b = (_a = item.prices) === null || _a === void 0 ? void 0 : _a.firstPriceInTheCart) === null || _b === void 0 ? void 0 : _b.centAmount) * item.quantity; })) === null || _d === void 0 ? void 0 : _d.reduce((prev, current) => prev + current, 0)) || 0;
        const totalPrice = ((_e = cartViewBody === null || cartViewBody === void 0 ? void 0 : cartViewBody.total) === null || _e === void 0 ? void 0 : _e.centAmount) || 0;
        const shippingCosts = ((_g = (_f = cartViewBody === null || cartViewBody === void 0 ? void 0 : cartViewBody.shippingCosts) === null || _f === void 0 ? void 0 : _f.costs) === null || _g === void 0 ? void 0 : _g.centAmount) || 0;
        const paymentCosts = ((_h = cartViewBody === null || cartViewBody === void 0 ? void 0 : cartViewBody.paymentCosts) === null || _h === void 0 ? void 0 : _h.centAmount) || 0;
        const subTotal = totalPrice - shippingCosts - paymentCosts;
        const subTotalWithoutPaymentCosts = totalPrice - paymentCosts;
        const totalDiscount = originSubTotal - subTotal;
        return {
            subTotal,
            originSubTotal,
            totalDiscount,
            totalPrice,
            shippingCosts,
            paymentCosts,
            subTotalWithoutPaymentCosts,
        };
    }
    setUserEmail(userEmail) {
        this.userEmail = userEmail;
    }
    /* Create cart and store it in the localstorage */
    async createCart(isLoggedIn) {
        var _a, _b;
        try {
            // two different calls to create cart if anonymous or logged user
            let newCart;
            if (!isLoggedIn) {
                newCart = await this.options.api.createAnonymousCart();
            }
            else {
                await this.options.store.userStore.getUserData(false);
                newCart = await this.options.api.createLoggedUserCart({
                    customerId: (_a = this.options.store.userStore.userData) === null || _a === void 0 ? void 0 : _a.ctp_id,
                    loyaltyCardNumber: (_b = this.options.store.userStore.userData) === null || _b === void 0 ? void 0 : _b.loyaltycardnumber,
                });
            }
            await this.getCartView(newCart.body.id);
            this.isCartViewLoaded = true;
        }
        catch (e) {
            console.log(e);
        }
    }
    /* Assign cart to user when logging in with a cart as anonymous user */
    async assignCartFromAnonymousUser() {
        var _a;
        try {
            const cartView = localStorage.getItem("createdCart");
            const newCart = await assignCart(((_a = cartView === null || cartView === void 0 ? void 0 : cartView.body) === null || _a === void 0 ? void 0 : _a.id) || "");
            this.setCart(newCart);
            return true;
        }
        catch (e) {
            console.log(e);
        }
    }
    async getCartView(cartId) {
        try {
            let response = await getCart(cartId);
            if (response.body.cartState !== "Active") {
                const replicateResponse = await this.options.api.replicateCart(cartId);
                if (replicateResponse && replicateResponse.statusCode === 200) {
                    response = await getCart(replicateResponse.body.replicatedCartId);
                }
            }
            if ((response === null || response === void 0 ? void 0 : response.statusCode) >= 200 && (response === null || response === void 0 ? void 0 : response.statusCode) < 300) {
                this.setCart(response);
                this.isCartViewLoaded = true;
            }
            return this.isCartViewLoaded;
        }
        catch (e) {
            console.log(e);
        }
    }
    removeActionList(id) {
        const actions = [...this.actionList];
        const index = this.actionList
            .filter((i) => i.action.action !== "removeDiscountCode")
            .findIndex((i) => i.snapshot.id === id);
        index > -1 && actions.splice(index, 1);
        this.actionList = [...actions];
    }
    clearActionList() {
        this.actionList = [];
    }
    async validateCart() {
        this.disableCheckoutButton = true;
        this.actionList = [];
        try {
            const actionList = await this.options.api
                .validateCart(this.cartView.body.id)
                .then((res) => { var _a; return ((_a = res.body) === null || _a === void 0 ? void 0 : _a.actionList) || []; });
            await this.getCartView(this.cartView.body.id);
            this.actionList = actionList;
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.disableCheckoutButton = false;
        }
    }
    /* Recalculate cart and get card view to refresh */
    async recalculateCart() {
        if (!this.cartView)
            return;
        this.disableCheckoutButton = true;
        try {
            const response = await this.options.api.recalculateCart(this.cartView.body.id, this.cartView.body.version);
            if (!response)
                await this.recoverBrokenCart();
            await this.getCartView(this.cartView.body.id);
            this.isCartViewLoaded = true;
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.disableCheckoutButton = false;
        }
    }
    async addProductToCart(isLoggedIn, productSku, quantity = 1) {
        try {
            /* Disable CTA while executing method */
            this.addProductFetching = true;
            this.isCtaDisabled = true;
            /* If cart don't exist in the localstorage call createCart */
            if (!localStorage.getItem("createdCart")) {
                await this.createCart(isLoggedIn);
            }
            this.initCart(); // Make sure the "createdCart" is loaded and set it as this.cartView
            if (!this.cartView) {
                await this.addProductToCart(isLoggedIn, productSku, quantity);
                return;
            }
            const response = await this.options.api.addProduct({
                cartId: this.cartView.body.id,
                version: this.cartView.body.version,
                sku: productSku,
                quantity,
            });
            if (!response) {
                await this.recoverBrokenCart();
            }
            else {
                await this.getCartView(this.cartView.body.id);
            }
            this.lastAddedSku = productSku;
            this.isAddedProductModalShown = true;
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.addProductFetching = false;
            this.isCtaDisabled = false;
        }
    }
    saveProductExtras(productId, productUrl, pdpData) {
        var _a;
        const cartProductExtras = (_a = localStorage.getItem("cartProductExtras")) !== null && _a !== void 0 ? _a : {};
        cartProductExtras[productId] = { url: productUrl, brand: pdpData.brand || "" };
        localStorage.setItem("cartProductExtras", cartProductExtras);
    }
    /* Close modal for added product */
    toggleAddedProductModalShown() {
        this.isAddedProductModalShown = false;
        this.lastAddedSku = "";
    }
    async deleteCartProduct(productId, quantity = 100) {
        this.deleteProductLoading = true;
        this.isCtaDisabled = true;
        try {
            const response = await this.options.api.deleteProduct({
                cartId: this.cartView.body.id,
                version: this.cartView.body.version,
                productId,
                quantity,
            });
            if (!response) {
                await this.recoverBrokenCart();
            }
            else {
                await this.getCartView(this.cartView.body.id);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.deleteProductLoading = false;
            this.isCtaDisabled = false;
        }
    }
    /* Add billing information to cart */
    async addBillingInformation(billingAddress, userAddress) {
        this.isCheckoutBillingFormError = false;
        this.isCtaDisabled = true;
        if ((billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.email) === undefined) {
            billingAddress.email = this.userEmail;
        }
        try {
            const response = await this.options.api.addBillingAddress(this.cartView.body.id, this.cartView.body.version, billingAddress);
            if (response && response.statusCode === 200) {
                await this.getCartView(this.cartView.body.id);
                if (userAddress && !validateUserAddress(userAddress)) {
                    const completeAddress = completeUserAddress(this.cartView.body.billingAddress);
                    if (userAddress.uid) {
                        await updateUserAddress({ ...completeAddress, defaultAddress: true }, userAddress.uid);
                    }
                    else {
                        await addUserAddress({
                            ...completeAddress,
                            defaultAddress: true,
                            gender: billingAddress.gender,
                        });
                    }
                }
                this.billingAddress = billingAddress;
            }
            else {
                this.isCheckoutBillingFormError = true;
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        }
        catch (e) {
            console.log(e);
            this.isCheckoutBillingFormError = true;
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
        finally {
            this.isCtaDisabled = false;
        }
    }
    /* Add shipping information to cart */
    async addShippingInformation(shippingAddress) {
        this.isDeliverAddressError = false;
        this.isCtaDisabled = true;
        if (shippingAddress.email === undefined) {
            shippingAddress.email = this.userEmail;
        }
        try {
            const response = await this.options.api.addShippingAddress(this.cartView.body.id, this.cartView.body.version, shippingAddress);
            if (response && response.statusCode === 200) {
                this.shippingAddress = shippingAddress;
            }
            else {
                this.isDeliverAddressError = true;
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            await this.getCartView(this.cartView.body.id);
            this.isCtaDisabled = false;
        }
    }
    async getShippingMethods() {
        var _a;
        if (!((_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body))
            return;
        try {
            const { id } = this.cartView.body;
            const response = await this.options.api.getShippingMethods(id);
            if (response.statusCode === 200) {
                // response.body.results[0] => id is used to set shippingCost
                // response.body.results[1] => id is used to set shippingCost to 0
                this.shippingMethods = response.body.results.filter((e) => !e.key.startsWith("standard_shipping_method"));
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    async setShippingCost(shippingMethodId) {
        var _a;
        if (!((_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body))
            return;
        this.isCtaDisabled = true;
        try {
            const { id, version } = this.cartView.body;
            await this.options.api.setShippingCost(id, shippingMethodId, version);
            await this.getCartView(id);
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.isCtaDisabled = false;
        }
    }
    async applyGiftcard(giftcardCode) {
        var _a;
        if (!((_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body))
            return;
        this.isCtaDisabled = true;
        try {
            const { id } = this.cartView.body;
            const response = await this.options.api.applyGiftcard(id, giftcardCode);
            if (response && response.statusCode === 200) {
                this.isGiftcardError = false;
                await this.getCartView(id);
                this.isCtaDisabled = false;
                return true;
            }
            else {
                this.isGiftcardError = true;
                console.log("Giftcard error");
            }
        }
        catch (e) {
            this.isGiftcardError = true;
            console.log(e);
        }
        finally {
            this.isCtaDisabled = false;
        }
        return false;
    }
    async removeGiftcard(giftcardCode, isRemoveAllGiftcards) {
        var _a;
        if (!((_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body))
            return;
        if (!isRemoveAllGiftcards)
            this.isCtaDisabled = true;
        try {
            const { id } = this.cartView.body;
            const response = await this.options.api.removeGiftcard(id, giftcardCode);
            if (response && response.statusCode === 200) {
                await this.getCartView(id);
            }
            else {
                console.log("Giftcard deletion error");
                if (!isRemoveAllGiftcards)
                    this.isCtaDisabled = false;
            }
        }
        catch (e) {
            console.log(e);
        }
        if (!isRemoveAllGiftcards)
            this.isCtaDisabled = false;
    }
    async removeAllGiftcards() {
        const giftcardsObj = this.cartView.body.appliedGiftcards;
        if (!giftcardsObj)
            return;
        this.isCtaDisabled = true;
        const validGiftcards = giftcardsObj.giftcards.filter((e) => e !== undefined);
        for (let i = validGiftcards.length - 1; i >= 0; i--) {
            const code = validGiftcards[i].code;
            if (code) {
                await this.removeGiftcard(code, true);
            }
        }
        this.isCtaDisabled = false;
    }
    async recoverBrokenCart() {
        const replicateResponse = await this.options.api.replicateCart(this.cartView.body.id);
        if (replicateResponse && replicateResponse.statusCode === 200) {
            await this.getCartView(replicateResponse.body.replicatedCartId);
            window.location.reload();
        }
    }
    async removePaymentCost() {
        var _a, _b, _c;
        if ((_c = (_b = (_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.paymentCosts) === null || _c === void 0 ? void 0 : _c.centAmount) {
            this.isCtaDisabled = true;
            try {
                const { id, version, paymentCosts } = this.cartView.body;
                await this.options.api.removePaymentCost(id, version, paymentCosts === null || paymentCosts === void 0 ? void 0 : paymentCosts.id);
                await this.getCartView(this.cartView.body.id);
            }
            catch (e) {
                console.log(e);
            }
            this.isCtaDisabled = false;
        }
    }
    async addLoyaltyCardNumber(loyaltyCardNumber) {
        var _a;
        if (!this.options.api.addLoyaltyCardNumber)
            return;
        if (!((_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body))
            return;
        this.isCtaDisabled = true;
        try {
            const { id, version } = this.cartView.body;
            await this.options.api.addLoyaltyCardNumber(id, version, loyaltyCardNumber);
            await this.getCartView(id);
        }
        catch (e) {
            console.log(e);
        }
        this.isCtaDisabled = false;
    }
    async setPaymentCost(paymentMethodExtraCost, paymentName) {
        var _a;
        if (!((_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body))
            return;
        this.isCtaDisabled = true;
        const { id, version } = this.cartView.body;
        try {
            if (this.getCartPrices.paymentCosts) {
                await this.removePaymentCost();
            }
            await this.options.api.setPaymentCost(id, version, paymentName, paymentMethodExtraCost);
            await this.getCartView(id);
        }
        catch (e) {
            console.log(e);
        }
        this.isCtaDisabled = false;
    }
    async cleanCosts() {
        var _a;
        if (!((_a = this.cartView) === null || _a === void 0 ? void 0 : _a.body))
            return;
        const { id, version, paymentCosts } = this.cartView.body;
        this.isCtaDisabled = true;
        try {
            await this.options.api.cleanCost(id, version, paymentCosts === null || paymentCosts === void 0 ? void 0 : paymentCosts.id);
            await this.getCartView(id);
        }
        catch (e) {
            console.log(e);
        }
        this.isCtaDisabled = false;
    }
}
