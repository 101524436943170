import { getDutchAddress } from "@/lib/jog";
import { useStores } from "@/stores";
import { FormikInputField, useCheckDutchAddress, useI18n, useSignUpForm } from "@jog/react-components";
import { getListCountry, handlePreSubmitValidation } from "@jog/shared";
import { FormikFormWrapper } from "@jog/theming";
import { AlertBox, Button, FormikSelectField } from "components";
import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import { useMemo } from "react";
export const SignUpForm = observer(({ email }) => {
    const { userStore } = useStores();
    const { initialValues, onSubmit, validate } = useSignUpForm({ userStore, email });
    const { getAddress } = useCheckDutchAddress({ getDutchAddress });
    const { $t } = useI18n();
    const countryOptions = useMemo(() => getListCountry($t("CountryList")), [$t]);
    return (<div className="text-sm">
            <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {(form) => {
            var _a;
            return (<Form>
                        {userStore.isRegistrationError && (<AlertBox type="error" text={$t("Something went wrong please try again")}/>)}
                        <FormikFormWrapper>
                            <div className="space-y-5 pb-8">
                                {/* COUNTRY */}
                                <div>
                                    <FormikSelectField id="country" label={`${$t("Country")} *`} name="country" smRight="4">
                                        <option disabled value="select">
                                            {$t("Select")}
                                        </option>
                                        {(_a = Object.entries(countryOptions)) === null || _a === void 0 ? void 0 : _a.map(([key, text]) => (<option key={key} value={key}>
                                                {text}
                                            </option>))}
                                    </FormikSelectField>
                                </div>

                                {/* Gender */}
                                <div>
                                    <FormikSelectField id="gender" label={`${$t("Sex")} *`} name="gender" smRight="4">
                                        <option disabled value="select">
                                            {$t("Select")}
                                        </option>
                                        <option value="MALE">{$t("Male")}</option>
                                        <option value="FEMALE">{$t("Female")}</option>
                                    </FormikSelectField>
                                </div>

                                {/* First Name */}
                                <div className="flex flex-wrap">
                                    <FormikInputField id="name" fieldName="name" label={`${$t("First Name")} *`}/>
                                </div>

                                {/* Tussen */}
                                <div className="flex flex-wrap">
                                    <FormikInputField id="insertion" fieldName="insertion" smRight="3" label={$t("insertion")}/>
                                </div>

                                {/* Last Name */}
                                <div className="flex flex-wrap">
                                    <FormikInputField id="familyName" fieldName="familyName" label={`${$t("Last Name")} *`}/>
                                </div>

                                {/* Postcode */}
                                <div className="flex flex-wrap">
                                    <FormikInputField id="postalCode" fieldName="postalCode" smRight="3" label={`${$t("Postcode")} *`} inputBlur={() => getAddress(form.values, form.errors, form.setFieldValue)}/>
                                </div>

                                <div className="flex flex-wrap">
                                    {/* Street num */}
                                    <FormikInputField id="houseNumber" fieldName="houseNumber" inputMode="numeric" smLeft="4" smRight="3" label={`${$t("Street number")} *`} inputBlur={() => getAddress(form.values, form.errors, form.setFieldValue)}/>
                                    {/* Toev */}
                                    <FormikInputField id="addition" fieldName="addition" smLeft="2" smRight="2" leftOffset="1" label={$t("Toevoeging")}/>
                                </div>

                                {/* Str Name */}
                                <div className="flex flex-wrap">
                                    <FormikInputField id="streetName" fieldName="streetName" label={`${$t("Street name")} *`}/>
                                </div>

                                {/* City */}
                                <div className="flex flex-wrap">
                                    <FormikInputField id="city" fieldName="city" label={`${$t("City")} *`}/>
                                </div>

                                {/* Tel num */}
                                <div className="flex flex-wrap">
                                    <FormikInputField id="phoneNumber" fieldName="phoneNumber" type="tel" label={$t("Telephone number")}/>
                                </div>

                                {/* Email addr */}
                                <div className="flex flex-wrap">
                                    <FormikInputField id="email" fieldName="email" isInputDisabled={true} label={`${$t("E-mail address")} *`}/>
                                </div>

                                {/* Password */}
                                <div className="flex flex-wrap">
                                    <FormikInputField id="password" fieldName="password" type="password" autocomplete="password" label={`${$t("Password")} *`}/>
                                </div>

                                {/* Confirm Password */}
                                <div className="flex flex-wrap">
                                    <FormikInputField id="confirmPassword" fieldName="confirmPassword" type="password" autocomplete="password" label={`${$t("Confirm password")} *`}/>
                                </div>

                                <div className="flex flex-wrap justify-end">
                                    <Button className="font-semibold" type="submit" onClick={() => handlePreSubmitValidation(form.errors)}>
                                        {$t("Next step")}
                                    </Button>
                                </div>
                            </div>
                        </FormikFormWrapper>
                    </Form>);
        }}
            </Formik>
        </div>);
});
