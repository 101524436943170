import { makeAutoObservable } from "mobx";
export class VoucherStore {
    constructor(options) {
        this.options = options;
        this.userVouchers = null;
        makeAutoObservable(this);
    }
    async removeVoucher(discountCodeId, cartId) {
        try {
            const response = await this.options.api.voucherRemove(discountCodeId, cartId);
            if (response && response.statusCode === 200) {
                await this.options.store.cartStore.getCartView(response.body.cart.id);
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    async getUserVouchers(loyaltyCardNumber) {
        try {
            const userVouchers = await this.options.api.getUserVouchers(loyaltyCardNumber);
            if (userVouchers) {
                this.userVouchers = userVouchers.body;
            }
        }
        catch (e) {
            console.log(e);
        }
    }
}
