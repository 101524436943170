import { replaceGroup } from "./helper";
export const parseVoucherTags = (voucherData, text) => {
    return replaceGroup([
        [/{loyaltyCardNumber}/g, voucherData.loyaltyCardNumber || ""],
        [/{expirationDate}/g, voucherData.expirationDate],
        [/{userEmail}/g, voucherData.userEmail || ""],
        // [/{secureCode}/g, voucherData.securityCode],
        [/{voucherCode}/g, voucherData.voucherCode],
        [/{voucherValue}/g, voucherData.value / 100],
    ], text);
};
export const isExpired = (date) => new Date(date) < new Date();
export const mapVoucherData = (voucherDocumentData, voucherCodeData, userData, voucherDisclaimer) => {
    var _a, _b;
    const voucherContent = {
        voucherCode: voucherCodeData.voucherCode,
        userEmail: userData.email,
        loyaltyCardNumber: userData === null || userData === void 0 ? void 0 : userData.loyaltycardnumber,
        imageUrl: voucherDocumentData.voucher_image.url,
        desktopImageUrl: voucherDocumentData.voucher_image.url,
        title: ((_b = (_a = voucherDocumentData.title) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.text) || "",
        description: voucherDocumentData.description,
        isRedeemed: voucherCodeData.isRedeemed,
        redeemInStore: voucherDocumentData.redeem_in_store,
        redeemOnline: voucherDocumentData.redeem_online,
        legalNotice: voucherDisclaimer,
        value: voucherCodeData.value,
        voucherType: voucherCodeData.voucherType,
        expirationDate: voucherCodeData.validTo,
        availableFrom: voucherCodeData.validFrom,
        // securityCode: voucherCodeData.securityCode,
    };
    return voucherContent;
};
export const getPrismicVoucherDocId = (voucherData) => {
    if (voucherData.voucherType === "MONEY_DISCOUNT") {
        switch (voucherData.value) {
            case 500:
                return "e5voucher";
            case 1000:
                return "e10voucher";
            case 1500:
                return "e15voucher";
            case 2000:
                return "e20voucher";
            case 2500:
                return "e25voucher";
            case 3000:
                return "e30voucher";
            case 3500:
                return "e35voucher";
            case 4000:
                return "e40voucher";
            case 4500:
                return "e45voucher";
            case 5000:
                return "e50voucher";
        }
    }
    else if (voucherData.voucherType === "PERCENTAGE") {
        switch (voucherData.value) {
            case 10:
                return "p10voucher";
            case 15:
                return "p15voucher";
            case 20:
                return "p20voucher";
            case 25:
                return "p25voucher";
            case 30:
                return "p30voucher";
            case 35:
                return "p35voucher";
        }
    }
};
