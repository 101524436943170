import styled from "@emotion/styled";
export const FormikFormWrapper = styled.div `
    .Error {
        width: 100%;
        color: #a54445;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    select {
        appearance: none;
        outline: none;
        padding: 11px 12px;
        width: 100%;
        height: 46px;
        background: #fff;
        border: ${({ errorMessage }) => `1px solid ${errorMessage ? '#a54445' : '#eeeeee'}`};
        border-radius: 0;
        &:focus {
            border: 1px solid #5C616B;
        }
    }
    button {
        margin-right: 15px;
    }
`;
